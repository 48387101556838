import(/* webpackMode: "eager", webpackExports: ["ShouldRenderTabs"] */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/next/dist/elements/DocumentHeader/Tabs/ShouldRenderTabs.js");
;
import(/* webpackMode: "eager", webpackExports: ["DocumentTabLink"] */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/next/dist/elements/DocumentHeader/Tabs/Tab/TabLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["VersionsPill"] */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/next/dist/elements/DocumentHeader/Tabs/tabs/VersionsPill/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["DefaultNavClient"] */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/next/dist/elements/Nav/index.client.js");
;
import(/* webpackMode: "eager", webpackExports: ["NavHamburger"] */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/next/dist/elements/Nav/NavHamburger/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["NavWrapper"] */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/next/dist/elements/Nav/NavWrapper/index.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/next/dist/prod/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["NavHamburger"] */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/next/dist/templates/Default/NavHamburger/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Wrapper"] */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/next/dist/templates/Default/Wrapper/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["APIViewClient"] */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/next/dist/views/API/index.client.js");
;
import(/* webpackMode: "eager", webpackExports: ["EditView"] */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/next/dist/views/Edit/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["LivePreviewClient"] */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/next/dist/views/LivePreview/index.client.js");
;
import(/* webpackMode: "eager", webpackExports: ["NotFoundClient"] */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/next/dist/views/NotFound/index.client.js");
;
import(/* webpackMode: "eager", webpackExports: ["DefaultVersionView"] */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/next/dist/views/Version/Default/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["AutosaveCell"] */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/next/dist/views/Versions/cells/AutosaveCell/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreatedAtCell"] */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/next/dist/views/Versions/cells/CreatedAt/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["IDCell"] */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/next/dist/views/Versions/cells/ID/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["VersionsViewClient"] */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/next/dist/views/Versions/index.client.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/richtext-lexical/dist/exports/client/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["IS_SAFARI","IS_IOS","IS_APPLE_WEBKIT"] */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/richtext-lexical/dist/lexical/utils/environment.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/ui/dist/assets/payload-favicon-dark.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/ui/dist/assets/payload-favicon-light.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/ui/dist/assets/payload-favicon.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/ui/dist/assets/static-og-image.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/@payloadcms/ui/dist/exports/client/index.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1770685668/src/rene-kathofer-payload/src/app/(payload)/custom.scss");
